import { render, staticRenderFns } from "./TrackOrder.vue?vue&type=template&id=f54ef39a"
import script from "./TrackOrder.vue?vue&type=script&lang=js"
export * from "./TrackOrder.vue?vue&type=script&lang=js"
import style0 from "./TrackOrder.vue?vue&type=style&index=0&id=f54ef39a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QPullToRefresh from 'quasar/src/components/pull-to-refresh/QPullToRefresh.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QColor from 'quasar/src/components/color/QColor.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QPullToRefresh,QIcon,QTooltip,QBtn,QImg,QDialog,QCard,QCardSection,QForm,QScrollArea,QList,QItem,QItemSection,QItemLabel,QAvatar,QSelect,QColor});qInstall(component, 'directives', {ClosePopup});
